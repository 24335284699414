import Image from "react-image-enlarger";
import React from "react";

export const Services = (props) => {
  function SingleSource({ src }) {
    const [zoomed, setZoomed] = React.useState(false);
  
    return (
      <div style={{ margin: "0.25rem" }}>
        <Image
          style={{ width: "200px", height: "150px" }}
          zoomed={zoomed}
          src={src}
          onClick={() => setZoomed(true)}
          onRequestClose={() => setZoomed(false)}
        />
      </div>
    );
  }  

  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Oferta</h2>
        </div>
        
          {props.data
            ? props.data.map((d, i) => (
              <div className="row">
                <div key={`${d.name}-${i}`} className="col-md-12">
                  {" "}
                  <div key={``} className="service-desc">
                    <h3>{d.name}</h3>
                    <h3>{d.text}</h3>
                    <div className="service-images">
                      {d.images.map((image, i) => (
                        <SingleSource key={i} src={image.src} />
                      ))}
                    </div>
                  </div>
                </div>
                </div>
              ))
            : "loading"}
      </div>
    </div>
  );
};
