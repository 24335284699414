import React from "react";

export const About = (props) => {
  return (
    <div id="about" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Kontakt</h2>
        </div>
        <div className="contact-data">
        <div className="row">
        Goghvin. Knowledge & Skills.
        </div>
        <div className="row">
        JJC Consulting & Beheer
        </div>
        <div className="row">
        Heulenslag 75
        </div>
        <div className="row">2971 VH Bleskensgraaf</div>
        <div className="row">Netherlands</div>
        <div className="row">
          tel. 
        <a href="tel:+31618710297">+31 618710297</a>, <a href="tel:+48504454859">+48 504454859</a>
        </div>
        <div className="row">
        e-mail: <a href="mailto:biuro@goghvin.pl">biuro@goghvin.pl</a>
        </div>
        </div>
      </div>
    </div>
  );
};
